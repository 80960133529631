import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import { Box, Container, Grid, Themed } from "theme-ui";
import { Link } from "gatsby";
import ContainerNarrow from "../components/container-narrow";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <Grid gap={[5, 5, 5, 7, 7, 8]} sx={{
        gridTemplateColumns: `repeat(auto-fit, minmax(17.08rem, 1fr))`,
        py: 6
      }} mdxType="Grid">
        <Box sx={{
          ul: {
            padding: 0,
            margin: 0,
            listStyle: `none`
          },
          li: {
            marginBottom: [4, 4, 4, 3, 3, 3]
          }
        }} mdxType="Box">
          <h1>{`Reflexiones en torno a las violencias machistas a través de diversas voces feministas desde Cataluña, el Estado Español y América Latina.`}</h1>
          <p>{`En un escenario marcado internacionalmente por la violencia machista se oyen las voces de las feministas, que gritamos para vencer al auténtico enemigo, el sistema patriarcal. `}</p>
          <p><strong parentName="p">{`Participantes`}</strong></p>
          <ul>
            <li parentName="ul">
              <Themed.a as={Link} to={`/lolita-chavez`}>Lolita Chávez</Themed.a>.<br /><small>Indígena guatemalteca, activista y feminista defensora de los DDHH (Guatemala)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/pastora-filigrana`}>Pastora Filigrana</Themed.a>.<br /><small>Abogada y defensora de Derechos Humanos (Sevilla)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/lilian-celiberti`}>Lilián Celiberti</Themed.a>.<br /><small>Feminista e integrante de Cotidiano Mujer (Uruguay)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/montserrat-cervera`}>Montserrat Cervera</Themed.a>.<br /><small>Activista feminista de Novembre Feminista (Ca La Dona - Barcelona)</small>
            </li>
          </ul>
          <p><strong parentName="p">{`Moderación`}</strong></p>
          <p>{`Nuria Alabao.`}<br /><small>{`Periodista y coordinadora de Feminismos de Ctxt.es`}</small></p>
        </Box>
        <Box sx={{
          ".mdx-embed": {
            backgroundColor: `primary`,
            padding: 4
          }
        }} mdxType="Box">
          <YouTube youTubeId="BwllJ_02F-w" mdxType="YouTube" />
          <Themed.p><small>Resumen</small></Themed.p>
        </Box>
      </Grid>
    </Container>
    <ContainerNarrow mdxType="ContainerNarrow">
      <p>{`Con `}<strong parentName="p">{`Lolita Chávez`}</strong>{` nos acercamos a la cosmovisión planetaria del territorio de Abya Yala de la que tanto tenemos que aprender. Las compañeras de Latinoamérica nos proponen un modelo de vida, que también es un modelo político, que pone en el centro el bienestar colectivo y el equilibrio de la naturaleza y el cosmos. También nos narra la experiencia de su territorio contra las empresas extractivistas, cómplices de les estados opresores y feminicidas, y de cómo la fuerza del territorio sobre éstas es un posicionamiento a favor de la vida. `}</p>
      <p>{`Con `}<strong parentName="p">{`Pastora Filigrana`}</strong>{`, abordamos la necesidad de leer las violencias machistas desde una mirada amplia, a través de su experiencia como abogado en los sectores precarizados de trabajadoras de la tierra, servicios domésticos y el sector del cuidado, desarrolla la importancia que poner un marco a estas violencias y de luchar contra el auténtico enemigo: el orden económico. Con su mirada interseccional, Pastora pone en evidencia que el capitalismo necesita de la desigualdad, de la diferencia entra la repartición de trabajo y bienes, y va de la mano del patriarcado, el racismo y el colonialismo. `}</p>
      <p><strong parentName="p">{`Lilian Celiberti`}</strong>{` nos propone una reflexión esperanzadora de los movimientos feministas actuales, que avanzan hacia una perspectiva más colectiva, más pública, más diversa que explosiona, grita, habita y denuncia desde la perspectiva del tejer historias y acompañarnos en la lucha. `}</p>
      <p>{`Finalmente, `}<strong parentName="p">{`Montserrat Cervera`}</strong>{` nos propone plantearnos qué oportunidades tenemos después de la oscuridad que hemos vivido durante la pandemia y nos recuerda la importancia de la idea del cuidado entre compañeras, la fuerza de los movimientos sociales y el no punitivismo de los discursos feministas actuales. `}</p>
      <p>{`La experiencia de estas cuatro voces feministas y las aportaciones y preguntas del público nos dejan un mensaje que define el espíritu de las Jornadas Internacionales: El feminismo no es solamente pelear por la igualdad de derechos entre mujeres y hombres, porque esto termina siendo la igualdad de algunas mujeres con los hombres. Sino que el feminismo es una teoría política impugnatoria contra el orden global injusto y esto es contra lo que hay que luchar.`}</p>
      <Box sx={{
        ".mdx-embed": {
          backgroundColor: `primary`,
          padding: 4,
          mt: 6
        }
      }} mdxType="Box">
        <YouTube youTubeId="lFTcXDJzM-Y" mdxType="YouTube" />
        <Themed.p><small>Mesa redonda</small></Themed.p>
      </Box>
    </ContainerNarrow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      